<template>
  <div class="modal modal-overlay" @click.self="$emit('close')">
    <div class="modal-contents">
      <div><img width="500" height="" :src="'/static/' + selectItemUrl + '.jpg'"></div>
      <div class="spece10"/>
      <div class="itm-msg">{{ selectItem }} を表示</div>
      <div class="spece10"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalWindow',
  props: ['selectItem', 'selectItemUrl'],
  mounted () {
    // console.log(this.selectItem)
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.modal {
  &.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}
.modal-contents {
  display: block;
}
.spece10{
  padding: 10px;
}
.spece30{
  padding: 30px;
}
.itm-msg {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  background-color: rgb(84, 90, 73);
  /*
  box-sizing: border-box;
  border: 1px solid rgb(255, 242, 187);
  */
}
</style>
