<template>
  <div class="about">
    <div class="spece10"/>
    <!--
      <AboutComp msg="Home"/>
    -->
    <div class="title">{{ info }}</div>

    <div class="about-container">
      <ul class="list1" v-for="(item, key) in items" :key="key">
        <li class="list1-item" v-for="(itm, key2) in item" :key="key2">
          <div class="list1-item-inner" v-on:click="modal(itm, key, key2)">
            <!-- <img width="250" height="250" src="/static/temporary1.png"><br> -->
            <img style="vertical-align: bottom" width="250" height="" :src="'/static/list' + key + key2 + '.jpg'"><br>
          </div>
          <div class="spece10"/>
            <div class="itm-msg">
              {{ itm }}
            </div>
          <div class="spece10"/>
        </li>
      </ul>
    </div>
    <div class="spece30"></div>
    <ModalWindow :select-item="selectItem" :select-item-url="selectItemUrl" v-show="showModal" @close="closeModal" />
    <!-- <input id="input_1">
    <p id="result"></p> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import AboutComp from '@/components/aboutComp.vue'
// import axios from 'axios'
import ModalWindow from '@/components/modalWindow.vue'

export default {
  name: 'about',
  components: {
    ModalWindow
    // AboutComp,
  },
  data () {
    return {
      info: 'Photos',
      massage: '',
      items: [],
      selectItem: '',
      selectItemUrl: '',
      showModal: false
    }
  },
  mounted () {
    // const input = document.getElementById('input_1')
    // input.addEventListener('keyup', function () {
    //  const result = document.getElementById('result')
    //  if (!input.value) return (result.textContent = '')
    //
    //  const params = new URLSearchParams()
    //  params.append('input_val', this.value)
    //
    //  axios.post('processing.php', params).then(response => {
    //    result.innerText = response.data
    //  }).catch(error => {
    //    console.log(error)
    //  })
    // })
    this.setItems()
  },
  methods: {
    setItems () {
      this.items = [
        ['写真 0', '写真 1', '写真 2'],
        ['写真 3', '写真 4', '写真 5'],
        ['写真 6', '写真 7', '写真 8'],
        ['写真 9', '写真 10', '写真 11'],
        ['写真 12', '写真 13', '写真 14']
      ]
    },
    modal (item, key, key2) {
      this.selectItem = item
      this.showModal = true
      this.selectItemUrl = 'list' + key + key2
    },
    closeModal () {
      this.showModal = false
    }
  }
}
</script>
<style scoped lang="scss">
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    width: 250px;
  }
  .list1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .list1-item {
    box-sizing: border-box;
    &img {
      width: 100%;
    }
  }
  .list1-item-text {
    border:solid 1px #555;
    padding-top: 10px;
  }
  .list1-item-inner {
    width: 250px;
    /* height: 250px; */
  }
  .list1-item-inner:hover {
    transform: translate(-2px, -2px);
    box-shadow: 5px 5px;
  }
  .spece10{
    padding: 10px;
  }
  .spece30{
    padding: 30px;
  }
  .about-container {
    width: 70%;
    margin: 0 auto;
  }
  .title {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
  }
  .itm-msg {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    box-sizing: border-box;
    border: 1px solid rgb(255, 242, 187);
  }
</style>
